export const settingsFragments = `
items {
    googleAnalyticsCode
    googleMapsAPICode
    dswPartnersList {
      content {
        ... on DswPartner {
          title
          image {
            url
          }
          link {
            url
          }
        }
      }
    }
    facebook
    twitter
    instagram
    linkedIn
    youtube
    tikTok
    search
    lightLogo {
      url
    }
    darkLogo {
      url
    }
    booking {
      name
      url
    }
    mobileNavigation {
      content {
        ... on LinkGroup {
          urls {
            url
            name
          }
        }
      }
    }
    mainNavigation {
      content {
        ... on NavigationItem {
          innerTitle
          subtitle
          innerDescription
          link {
            name
            url
          }
          itemChildren {
            content {
              ... on NavigationItem {
                innerTitle
                subtitle
                innerDescription
                link {
                  name
                  url
                }
              }
            }
          }
        }
      }
    }
    footerMenu {
      content {
        ... on LinkGroup {
          urls {
            url
            name
          }
        }
      }
    }
    footerMenuMobile {
      content {
        ... on LinkGroup {
          urls {
            url
            name
          }
        }
      }
    }
    footerText
    copyrightText
    bottomLinks {
      name
      url
    }
    footerBackgroundImage {
      url
    }
}
`;

export const pageFragments = `
fragment Link on Link {
  name
  target
  url
  udi
}
fragment Page on NewDSWPage {
  contentTypeAlias
  id
  name
  url
  title
  description
  metadataTitle
  metadataDescription
  metadataKeywords
  disableSearchEngineIndexing
  createDate
  updateDate
  featuredImage {
    url
  }
  socialMediaImage {
    url
  }
  cssClass
  newDSWBody {
    content {
      ... on DswBannerSlides {
          __typename
          contentTypeAlias
          dswBannerSliderList {
            content {
              ... on DswBannerSlide {
                title
                subtitle
                text
                videoUrl
                link {
                 ...Link
                }
                image {
                  url
                }
              }
            }
          }
        }
        ... on DswActivitiesCards {
          __typename
          contentTypeAlias
          dswActivitiesCardsList {
            content {
              ... on DswActivityCard {
                title
                links {
                  url
                  name
                }
                image {
                  url
                }
                mobileImage {
                  url
                }
                buttons {
                  name
                  target
                  url
                }
              }
            }
          }
        }
        ... on DswExplore {
          title
          subtitle
          contentTypeAlias
          image {
            url
          }
          link {
            name
            url
          }
          text
          videoUrl
          boxedWidth
        }
        ... on DswAppCTA {
          title
          subtitle
          androidLink
          contentTypeAlias
          image {
            url
          }
          iosLink

          text
        }
        ... on DswAcademiesIntroText {
          title
          textContent
          academyContactEmail
          academyContactName
          academyContactPhone
          contentTypeAlias
          websiteLink {
            name
            url
            target
          }
          facebook
          instagram
          twitter
          youtube
          linkedin
          tiktok
          logo {
            url
          }
        }
        ... on DswUpcomingEvents {
          contentTypeAlias
          title
          text
          link {
            ...Link
          }
          numberOfItems
        }
        ... on DswPartners {
          contentTypeAlias
          partnersList {
            content {
              ... on DswPartner {
                title
                image {
                  url
                }
                link {
                  ...Link
                }
              }
            }
          }
        }
        ... on DswVideoPlayer {
          title
          videoUrl
        }
        ... on DswExplore {
          title
          subtitle
          text
          contentTypeAlias
          link {
            ...Link
          }
          image {
            url
          }
          videoUrl
        }
        ... on DswAppCTA {
          contentTypeAlias
          title
          subtitle
          text
          image {
            url
          }
          iosLink
          androidLink
        }
        ... on DswPageBanner {
          contentTypeAlias
          title
          text
          image {
            url
          }
          videoUrl
          ctaText
          isInnerPage
        }
        ... on DswFullWidthCTA {
          contentTypeAlias
          title
          text
          link {
            ...Link
          }
          image {
            url
          }
        }
        ... on DswFitnessActivities {
          title
          description
          contentTypeAlias
          cardPrice
          cardText
          cardTitle
          membershipButton {
            url
            name
          }
          dswFitnessActivitiesList {
            content {
              ... on DswFitnessActivity {
                title
                text
                image {
                  url
                }
                links {
                  ...Link
                }
              }
            }
          }
        }
        ... on DswSportsActivities {
          title
          contentTypeAlias
          dswSportsList {
            ... on NewDSWPage {
              id
              name
              url
              title
              description
              icon {
                url
              }
            }
          }
        }
        ... on DswSportPrices {
          contentTypeAlias
          title
          textContent
          paddingBottom
          link {
            ...Link
          }
          link2 {
            ...Link
          }
          sportsLogo {
            url
          }
          dswSportPriceCardLists {
            content {
              ... on DswSportPriceList {
                title
                dswSportPriceCards {
                  content {
                    ... on DswSportPriceCard {
                      title
                      days
                      hours
                      price
                      color
                    }
                  }
                }
              }
            }
          }
        }
        ... on DswMediaGallery {
          contentTypeAlias
          title
          text
          paddingBottom
          isSportsDetails
          images {
            url
          }
        }
        ... on DswCTA {
          contentTypeAlias
          title
          text
          link {
            ...Link
          }
          image {
            url
          }
        }
        ... on DswBookingPromotions {
          contentTypeAlias
          title
          text
          link {
            ...Link
          }
          image {
            url
          }
          dswPromotionsPicker {
            ... on DswPromotionPage {
              id
              name
              url
              title
              description
              price
            }
          }
        }
        ... on DswIntroText {
          contentTypeAlias
          title
          htmlContent
          fontSm
          link {
            ...Link
          }
        }
        ... on DswAcademies {
          contentTypeAlias
          title
          dswAcademyList {
            ... on NewDSWPage {
              id
              name
              url
              title
              description
              icon {
                url
              }
            }
          }
        }
        ... on DswAcademiesCards {
          contentTypeAlias
          title
          dswAcademyCardList {
            content {
              ... on DswIcons {
                title
                icon {
                  url
                }
              }
            }
          }
        }
        ... on DswIntroTextWithIconList {
          contentTypeAlias
          title
          titleLogo {
            url
          }
          text
          icons {
            content {
              ... on DswIcons {
                title
                icon {
                  url
                }
              }
            }
          }
          cta { 
            ... Link
          }
        }
        ... on DswPageBannerNoImage {
          contentTypeAlias
          title
          text
          bgWhite
          isVenue
        }
        ... on DswContactForm {
          contentTypeAlias
          title
          text
          formId
          policyText
          buttonText
          cardsList {
            content {
              ... on DSWAddressCard {
                title
                infoText
                facebook
                instagram
                tiktok
                twitter
                youtube
              }
            }
          }
        }
        ... on DswAddressCards {
          contentTypeAlias
          cardsList {
            content {
              ... on DSWAddressCard {
                title
                infoText
              }
            }
          }
        }
        ... on DswLocationMap {
          contentTypeAlias
          locationList {
            content {
              ... on DswLocationMapItem {
                title
                address
                geoLocation
                link {
                  url
                  name
                  target
                }
              }
            }
          }
        }

        ... on DswVideoLoopBlock {
          contentTypeAlias
          video
        }

        ... on DswSportsCards {
          contentTypeAlias
          title
          cardsList {
            content {
              ... on DswSportsCard {
                image {
                  ... Image
                }
                title
                text
              }
            }
          }
        }

        ... on DswEventsList {
          contentTypeAlias
          title
          eventTypes
          sportsCategory
          fitnessTypes
          calendarText
        }
        ... on Faqs {
          contentTypeAlias
          questions {
            content {
              ... on Faq {
                category
                question
                answer
              }
            }
          }
        }
        ... on DswVenueMap {
          contentTypeAlias
          venueTitle
          venueText
          image {
            url
          }
        }
        ... on DswLegends {
          contentTypeAlias
          title
          text
          dswLegendsList {
            content {
              ... on DswLegend {
                legendName
                legendActivity
                legendImage {
                  url
                }
                legendLink {
                  ...Link
                }
              }
            }
          }
        }
        ... on DswDiningAndShopping {
          contentTypeAlias
          text
          title
          dswDiningAndShoppingList {
            content {
              ... on DswDiningAndShoppingCard {
                title
                text
                image {
                  url
                }
              }
            }
          }
        }
        ... on DswInstagram {
          contentTypeAlias
          title
          text
          instagramLink
          isClip
          isLarge
        }
        ... on DswPartnersList {
          contentTypeAlias
          title
          text
          partnersListForSponsors {
            content {
              ... on DswPartner {
                title
                image {
                  url
                }
                link {
                  ...Link
                }
              }
            }
          }
        }
        ... on DswPromotions {
          contentTypeAlias
          promotionLists {
            content {
              ... on DswPromotionsList {
                discount
                title
                startDate
                endDate
                text
                buttonLabel
                buttonUrl
              }
            }
          }
        }
        ... on DswMedicalFacilities {
          title
          detailsText
          contentTypeAlias
        }
        ... on DswIntroTextWithCard {
          introTitle
          introText
          cardPrice
          cardText
          cardTitle
          contentTypeAlias
          linksButtons {
            url
            target
            name
          }
        }
        ... on HtmlContent {
          htmlContent
          title
          contentTypeAlias
        }
        ... on DswBookingCalendar {
          title
          text
          dswBookingCalendarList {
            content {
              ... on DswBookingCalendarDay {
                fitnessDay
                dSWCalendarItems {
                  content {
                    ... on DswBookingCalendarItem {
                      fitnessHours
                      fitnessName
                      fitnessColor
                    }
                  }
                }
              }
            }
          }
          contentTypeAlias
        }
        ... on SearchResults {
          contentTypeAlias
          title
        }
        ... on DswParkingInfo {
          parkingInfoTitle
          parkingInfoText
          contentTypeAlias
          parkingInfoHtmlText
          parkingInfoButton {
            name
            target
            url
          }
        }
    }
  }
}
`;
