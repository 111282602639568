import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { pageFragments, settingsFragments } from '../fragments/preview';
import Page from '../templates/page';

export default function Preview() {
  const [dswPage, setDswPage] = useState(null);
  const [dswSettings, setDswSettings] = useState(null);
  const isBrowser = typeof window !== 'undefined';
  const queryParams = new URLSearchParams(isBrowser && window.location.search);
  const [message, setMessage] = useState('Loading Preview');
  const langCode = queryParams.get('lang') || 'en-US';
  const id = queryParams.get('id');

  useEffect(() => {
    const loadPage = (type, settings) => {
      let query = '';

      switch (type) {
        case 'newDSWPage':
          query = `
            ${pageFragments}
            query ($id: ID!, $lang: String!) {
              content(id: $id, culture: $lang, preview: true) {
                  ...Page
                }
            }
          `;
          break;
        default:
          setMessage('Invalid document type');
          return;
      }

      const options = {
        method: 'POST',
        url: 'https://graphql.umbraco.io',
        headers: {
          'content-type': 'application/json',
          'Umb-Project-Alias': process.env.GATSBY_UMBRACO_ENVIRONMENT,
          'Api-Key': process.env.GATSBY_PREVIEW_API_KEY,
        },
        data: {
          query,
          variables: {
            id,
            lang: langCode,
          },
        },
      };
      axios
        .request(options)
        .then((response) => {
          if (type === 'newDSWPage') {
            setDswPage({ umbraco: { newDSWPage: response.data.data.content } });
            setDswSettings(settings);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const getPageType = async (settings) => {
      const options = {
        method: 'POST',
        url: 'https://graphql.umbraco.io',
        headers: {
          'content-type': 'application/json',
          'Umb-Project-Alias': process.env.GATSBY_UMBRACO_ENVIRONMENT,
          'Api-Key': process.env.GATSBY_PREVIEW_API_KEY,
        },
        data: {
          query: `
              query ($id: ID!, $lang: String!) {
                  content(id: $id, culture: $lang, preview: true) {
                    contentTypeAlias
                  }
              }
              `,
          variables: {
            id,
            lang: langCode,
          },
        },
      };

      axios
        .request(options)
        .then((response) => {
          loadPage(response.data.data.content.contentTypeAlias, settings);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const getSettings = async () => {
      const options = {
        method: 'POST',
        url: 'https://graphql.umbraco.io',
        headers: {
          'content-type': 'application/json',
          'Umb-Project-Alias': process.env.GATSBY_UMBRACO_ENVIRONMENT,
          'Api-Key': process.env.GATSBY_PREVIEW_API_KEY,
        },
        data: {
          query: `
            query ($lang: String) {
              allNewDSWSettings(culture: $lang) {
                ${settingsFragments}
              }
            }
        `,
          variables: {
            lang: langCode,
          },
        },
      };
      axios
        .request(options)
        .then((response) => {
          getPageType(response.data.data.allNewDSWSettings.items[0]);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    if (!id) {
      setMessage('No id found');
      return;
    }
    getSettings();
  }, []);
  return isEmpty(dswPage) || isEmpty(dswSettings) ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        hight: '100%',
      }}
    >
      <h1>{message}</h1>
    </div>
  ) : (
    <Page
      pageContext={{
        langCode: langCode.split('-')[0],
        lang: langCode,
        settings: dswSettings,
      }}
      data={dswPage}
    />
  );
}
